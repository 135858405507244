import React, { useEffect, useState, useRef, useCallback } from 'react';
import useClickHandlers from 'features/Installation/hooks/useClickHandlers';
import classes from '../InstallationDetails.module.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { installationStore } from '@Store/installationStore';
import { toast } from 'react-toastify';
import { getUserSFDetails } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';
import { dashboardQuery } from '../../../dahboard/dashboard.query';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@Components/Button';

const WheelService = () => {
  const {
    installationDateValue,
    setStepPrev,
    setWheelServiceDetails,
    setNextButton,
    wheelServiceDetails,
    setInstallationDateDetails,
  } = installationStore();
  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');

  const email = getUserSFDetails().email;
  const { data, isLoading } = dashboardQuery.useMultiProducts(email, ids, true);
  //alert for redirecting
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);
  const { setSpinner, isActive } = spinnerStore();
  const [equipData, setEquipData] = useState(data);
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
    if (data) {
      setEquipData(data);
    }
  }, [data]);
  // State to track the number of employees selected, default is 1
  const [numEmployees, setNumEmployees] = useState(1);

  // State to track the names of the employees
  const [employees, setEmployees] = useState([{ firstName: '', lastName: '' }]);
  const [mileage, setMileage] = useState(''); // State to store the mileage input
  const [imageData, setImageData] = useState();
  const [imageName, setImageName] = useState();
  // Load data from Storage when the component mounts
  useEffect(() => {
    if (wheelServiceDetails.length >= 1) {
      const storedData = JSON.parse(wheelServiceDetails);
      console.warn(storedData);
      if (storedData) {
        setEmployees(
          storedData?.employees || [{ firstName: '', lastName: '' }],
        );
        setMileage(storedData?.mileage || '');
        setNumEmployees(storedData?.numEmployees || 1);
        setImageData(storedData?.image || '');
        setImageName(storedData?.image?.name || '');
      }
    }
  }, []);
  console.warn(imageName, 'imageName');

  const formatDate = (date) => date.toISOString().split('T')[0];
  const currentDate = dayjs();
  const isEmpty = (installationDateValue) =>
    JSON.stringify(installationDateValue) === '{}';
  const [value, setValue] = useState(
    !isEmpty(installationDateValue)
      ? dayjs(installationDateValue).toISOString()
      : dayjs(new Date()).toISOString(),
  );
  const getStartMonthsAgo = () => {
    // Create a new Date object for the current date
    const now = new Date();
    // Calculate the date six months ago
    const MonthsAgo = new Date(now.setMonth(now.getMonth() - 2));
    return formatDate(MonthsAgo);
  };
  //WheelService Upload image Logic starts from here
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    // Update timestamp every second
    const interval = setInterval(() => {
      setTimestamp(Date.now());
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);
  const [file, setFile] = useState();
  const [uploadedImg, setUploadedImg] = useState();

  const handleFileChange = async (e) => {
    if (e.target.files.length === 0) {
      // No file selected, ignore\

      return;
    } else {
      setFile(e.target.files[0]);
      const selectedImagedetails = e.target.files[0];
      if (!selectedImagedetails.type.startsWith('image/')) {
        toast.error('Please select an image file!', {
          position: toast.POSITION.TOP_CENTER,
        });
        //  setFileSize(null);
        return;
      }
      // Validate the file size (5 MB = 5 * 1024 * 1024 bytes)
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (selectedImagedetails.size > maxSizeInBytes) {
        toast.error(
          'The selected warranty image exceeds the 5 MB size limit. Please choose a smaller file and try again.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
        //  setFileSize(null);
        return;
      }
      setUploadedImg(URL.createObjectURL(e.target.files[0]));
      const uploadImgURL = await convertBase64(selectedImagedetails);
      const imageSplit = uploadImgURL.split(',');
      const namesplit = selectedImagedetails.name.split('.');
      let fileName = equipData[0].equipmentNumber + '_' + timestamp;
      namesplit.forEach((item, index) => {
        if (index > 0) {
          fileName = fileName + '.' + item;
        }
      });
      const imageDetails = {
        name: fileName,
        size: selectedImagedetails.size,
        type: selectedImagedetails.type,
        uri: imageSplit[1],
        imageType: 2,
      };
      setImageData(imageDetails);
      setImageName(imageDetails?.name);
    }
  };

  // Update the number of employees and generate a new array for inputs
  const handleNumEmployeesChange = (event) => {
    const count = parseInt(event.target.value, 10);
    setNumEmployees(count);

    // Generate an array of empty employee objects based on the selected count
    const newEmployees = Array.from({ length: count }, (_, index) => ({
      firstName: employees[index]?.firstName || '',
      lastName: employees[index]?.lastName || '',
    }));

    setEmployees(newEmployees);
  };

  // Handle changes in individual input fields
  const handleInputChange = (index, field, value) => {
    const updatedEmployees = [...employees];
    // Validate alphanumeric characters
    if (/^[a-zA-Z0-9]*$/.test(value) || value === '') {
      updatedEmployees[index][field] = value;
      setEmployees(updatedEmployees);

      // Clear error when the user starts typing
      if (value && fieldErrors[`${field}-${index}`]) {
        setFieldErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[`${field}-${index}`]; // Remove error when field has value
          return newErrors;
        });
      }
    } else {
      // Optionally, set error if input is invalid
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [`${field}-${index}`]: 'Only alphanumeric characters are allowed.',
      }));
    }
  };
  const [fieldErrors, setFieldErrors] = useState({}); // To store error messages on focus
  const handleFocusIn = (index, field) => {
    const fieldValue = employees[index][field];
    if (!fieldValue) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [`${field}-${index}`]: `${field} is required.`, // Show error if field is empty
      }));
    }
  };
  // Check if all required fields are filled
  const isFormValid = employees
    .slice(0, numEmployees)
    .every(
      (employee) =>
        employee.firstName.trim() !== '' && employee.lastName.trim() !== '',
    );

  // Handle change in mileage input field (same for all employees)
  const handleMileageChange = (event) => {
    const value = event.target.value;

    // Allow only digits (0-9)
    if (/^\d*$/.test(value)) {
      setMileage(value);
    }
    // setMileage(event.target.value);
  };
  const [photoOpen, setPhotoOpen] = useState(false);
  const handleClickOpen2 = () => {
    setPhotoOpen(true);
  };
  const handleClose2 = () => {
    setPhotoOpen(false);
  };
  const removeUploadedImage = (e) => {
    e.target.value = '';
    setUploadedImg('');
    setImageData('');
    setImageName('');
  };
  useEffect(() => {
    console.warn(isFormValid, 'isemployeesFormValid');
    setNextButton(!isFormValid);
  }, [employees, numEmployees, isFormValid]);
  useEffect(() => {
    setInstallationDateDetails(value);
  }, [value]);
  // Prepare data for submission
  const employeeData = {
    numEmployees: numEmployees,
    employees: employees, // List of employees (firstName, lastName)
    mileage: mileage.trim(), // Separate mileage field
    image: imageData,
  };

  // Convert employeeData to JSON string
  const jsonString = JSON.stringify(employeeData);
  const handleNextClick = (e) => {
    console.warn(jsonString, 'employees');
    setWheelServiceDetails(JSON.stringify(employeeData), true);
  };

  const handlePrevClick = () => {
    setStepPrev();
    setWheelServiceDetails('');
    setInstallationDateDetails({});
  };
  useClickHandlers(handleNextClick, handlePrevClick);

  return (
    <div className={classes.installationDetailCont}>
      <div className={classes.installationDetails}>
        <div>
          <div className={classes.dateContainer}>
            <p className={classes.installationDate}>Installation Date</p>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              className={classes.dateSelect}
            >
              <DatePicker
                sx={{
                  color: '#D2D2D2',
                  borderRadius: '2px',
                  borderWidth: '1px',
                  borderColor: '#D2D2D2',
                  border: '1px solid',
                  backgroundColor: '#fff',
                  minWidth: '280px',
                  maxWidth: '420px',
                  width: '100%',
                  // height: '38px',
                }}
                views={['year', 'month', 'day']}
                defaultValue={dayjs(value)}
                minDate={dayjs(getStartMonthsAgo())}
                maxDate={dayjs(new Date())}
                selected={value}
                onChange={(value) => setValue(value.toISOString())}
              />
            </LocalizationProvider>
          </div>
          <div className={classes.wheelService}>
            <div>
              <p>Number of Employees Trained</p>
              <select
                id="numEmployees"
                value={numEmployees}
                onChange={handleNumEmployeesChange}
                className={classes.employeesTrained}
              >
                {[1, 2, 3, 4].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>

              {/* Dynamically render input fields based on the selected number */}
              {/* Dropdown to select number of employees */}
              <div>
                {Array.from({ length: numEmployees }, (_, index) => (
                  <div
                    key={index}
                    className="employee-input"
                    style={{ marginTop: '10px' }}
                  >
                    <label
                      htmlFor={`firstName-${index}`}
                      className={classes.employeNames}
                      style={{ marginBottom: '10px', display: 'inline-block' }}
                    >
                      Employee Name #{index + 1}:
                    </label>
                    <br />
                    <label
                      htmlFor={`firstName-${index}`}
                      className={classes.employeNames}
                    >
                      First Name <span className={classes.formLabelReq}>*</span>
                    </label>
                    <input
                      type="text"
                      className={classes.employeNameInput}
                      id={`firstName-${index}`}
                      value={employees[index]?.firstName || ''}
                      placeholder="Enter Name"
                      onChange={(e) =>
                        handleInputChange(index, 'firstName', e.target.value)
                      }
                      onBlur={() => handleFocusIn(index, 'firstName')} // Validate on blur
                      aria-required="true"
                      style={{
                        borderColor: fieldErrors[`firstName-${index}`]
                          ? '#CE1F1F'
                          : '#D2D2D2',
                      }}
                    />
                    {fieldErrors[`firstName-${index}`] && (
                      <span style={{ color: '#CE1F1F', display: 'block' }}>
                        {fieldErrors[`firstName-${index}`]}
                      </span>
                    )}
                    <label
                      htmlFor={`lastName-${index}`}
                      className={classes.employeNames}
                    >
                      Last Name <span className={classes.formLabelReq}>*</span>
                    </label>
                    <input
                      type="text"
                      id={`lastName-${index}`}
                      value={employees[index]?.lastName || ''}
                      placeholder="Enter Name"
                      onChange={(e) =>
                        handleInputChange(index, 'lastName', e.target.value)
                      }
                      onBlur={() => handleFocusIn(index, 'lastName')} // Validate on blur
                      aria-required="true"
                      style={{
                        borderColor: fieldErrors[`lastName-${index}`]
                          ? '#CE1F1F'
                          : '#D2D2D2',
                      }}
                    />
                    {fieldErrors[`lastName-${index}`] && (
                      <span style={{ color: '#CE1F1F', display: 'block' }}>
                        {fieldErrors[`lastName-${index}`]}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.mileage}>
              <label htmlFor="mileage" className={classes.employeNames}>
                Mileage Number
              </label>
              <input
                type="text"
                id="mileage"
                value={mileage}
                onChange={handleMileageChange}
                placeholder="Please enter here"
              />
              {fieldErrors.mileage && (
                <span style={{ color: '#CE1F1F', display: 'block' }}>
                  {fieldErrors.mileage}
                </span>
              )}
            </div>

            <div className={classes.chooseFile}>
              <p>Please Upload Product Photography</p>
              <div className={classes.uploadImage}>
                <label htmlFor="fileInput">Choose Image</label>
                <input
                  type="file"
                  id="fileInput"
                  name="file"
                  onChange={handleFileChange}
                />
                <span>
                  {' '}
                  {imageName && (
                    <div className={classes.acServiceImage}>
                      {/* <img src={imageData.uri ?? ''} alt="Thumb" />
                       */}
                      <button
                        // onClick={imageViewerClick}
                        className={classes.imageViewer}
                        variant="outlined"
                        onClick={handleClickOpen2}
                      >
                        <p className={classes.hoverText}>
                          <h2 className={classes.rotaryTitle}>{imageName}</h2>
                          {/* <span className={classes.tooltip}>{imageName}</span> */}
                        </p>
                        {/* <p>{imageData.name}</p> */}
                      </button>
                      <button
                        onClick={removeUploadedImage}
                        className={classes.removeImgs}
                      >
                        X
                      </button>
                    </div>
                  )}
                </span>
              </div>
              <Dialog
                open={photoOpen}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <img
                      src={uploadedImg}
                      alt="Thumb"
                      className={classes.imageView}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose2}>Close</Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WheelService;
