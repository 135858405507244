import React, { useState, useEffect, useRef } from 'react';
import { installationStore } from '@Store/installationStore';
import classes from './NewLocation.module.scss';
import { MappedInput } from '@Components/Input';
import { InputController } from '@Components/Input/MappedInput';
import { InputField } from '@Components/Input';
import { useFormHook, f } from './NewLocation.form.initializer';
import useClickHandlers from 'features/Installation/hooks/useClickHandlers';
import useButtonsState from 'features/Installation/hooks/useButtonsState';
import { instalationQuery } from 'features/Installation/Installation.query';
import AutoComplete from 'features/Installation/Gmaps';
import { getUserSFDetails } from 'utils/getUserAccount';
import { useParams } from 'react-router-dom';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import { installationService } from '@Services/installation';
import DropdownField from '@Components/Input/Dropdown';
import { spinnerStore } from '@Store/spinnerStore';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
function NewLocation() {
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () =>
      window.removeEventListener('dom.disable_beforeunload', unloadCallback);
  }, []);
  const formRef = useRef(null);

  useEffect(() => {
    // Focus on the form's first input field when the component mounts
    const firstInput = formRef.current.querySelector('input');
    if (firstInput) {
      firstInput.focus();
    }
  }, []);
  const {
    setLocationDetails,
    setStepPrev,
    setNewLocation,
    newLocation,
    setNextButton,
    locationDetails,
    newlocationDetails,
    setNewLocationDetails,
    setFindLocation,
  } = installationStore();
  const endUserDetails = installationStore((state) => state.endUserDetails);
  let userSFData = getUserSFDetails();
  let userId = userSFData.id;
  if (endUserDetails?.id) {
    userId = endUserDetails?.id;
  }
  const { mutate, status, data } = instalationQuery.useMutationSaveLocation();
  const {
    mutate: updateMutate,
    status: updateStatus,
    data: updatedData,
  } = instalationQuery.useMutationUpdateSaveLocation();

  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');

  const { data: useProductData } = dashboardQuery.useMultiProducts(
    userSFData.email,
    ids,
    true,
  );
  const distributorId = useProductData[0].distributorId;
  const id = locationDetails?.id;
  const onSubmit = (values, actions) => {
    if (window.gtag) {
      window.gtag('event', 'NewLocation_FormSubmitted', {
        event_category: 'interaction',
        event_label: 'NewLocation_FormSubmitted',
        value: 1,
      });
      console.warn('gtag event NewLocation_FormSubmitted clicked');
    } else {
      console.warn('gtag is not defined yet');
    }
    setFindLocation(''); // recently added
    if (newlocationDetails?.id) {
      updateMutate({ ...values, userId, distributorId, id });
      setNewLocation(values);
    } else {
      mutate({ ...values, userId, distributorId });
      setNewLocation(values);
    }
  };

  const [countryIdData, setCountryIdData] = useState('');
  const [invalidZip, setInvalidZip] = useState(false);
  const { data: countryData } = instalationQuery.useCountriesList(false);
  const { setSpinner, isActive } = spinnerStore();
  const { data: stateData } = instalationQuery.useStateCode(
    countryIdData,
    false,
  );
  const [stateDataValues, setStateDataValues] = useState(stateData);
  const [invalidBusinessName, setInvalidBusinessName] = useState(false);

  // const { data: useBusinessNCheck } = instalationQuery.useBusinessNameCheck(
  //   userId,
  //   businessName,
  // );

  useEffect(() => {
    if (updateStatus === 'success') {
      const item = updatedData?.data?.response;
      setLocationDetails(item, true);
    }
  }, [updateStatus]);
  useEffect(() => {
    if (status === 'success') {
      const item = data?.data?.response;
      setLocationDetails(item, true);
      setNewLocationDetails(data?.data?.response);
    }
  }, [status]);

  useEffect(() => {
    if (countryData?.length) {
      const options = countryData?.map((ele) => {
        return {
          label: ele.countryName,
          value: ele.countryCode,
        };
      });
      f.rowThree.forEach((i) => {
        if (i.id === 'country') {
          i.options = options;
        }
      });
    }
  }, [countryData]);
  const [countryValue, setCountryValue] = useState('');
  useEffect(() => {
    setStateDataValues(stateData);
  }, [stateData]);
  useEffect(() => {
    if (stateDataValues) {
      const options = stateDataValues?.map((ele) => {
        return {
          label: ele.stateName,
          value: ele.stateCode,
        };
      });
      f.rowThree[1].options = options;
      form.setValues({ ...form.values });
    } else {
      setStateDataValues('');
      form.setFieldValue('state', '');
      form.setValues({ ...form.values });

      // alert('emptyCountry');
    }
  }, [stateDataValues, countryValue]);
  const form = useFormHook(onSubmit);
  useEffect(() => {
    if (newLocation.phoneNumber) {
      form.setValues({ ...newLocation });
      setNextButton(false);
    }
  }, []);

  useEffect(() => {
    countryData?.map((i) => {
      if (form.values.country === i.countryCode) {
        setCountryIdData(i.countryId);
      }
    });
  }, [countryData, form]);
  const { touched, isValid } = form;
  useButtonsState(touched, isValid);
  const handleNextClick = () => form.submitForm();
  const handlePrevClick = () => {
    setStepPrev();
  };
  useClickHandlers(handleNextClick, handlePrevClick);
  useEffect(() => {
    if (form.isValid && form.dirty && !invalidZip) {
      setNextButton(false);
    }
  }, [form.isSubmitting === false && form.values.phoneNumber !== '']);
  useEffect(() => {
    localStorage.setItem('phoneNumValue', form.values.phoneNumber);
  }, [form.values.phoneNumber]);

  useEffect(() => {
    if (invalidZip || !form.isValid) {
      setNextButton(true);
    } else {
      setNextButton(!(form.isValid && !invalidZip));
    }
  }, [invalidZip, form.isValid]);
  useEffect(() => {
    if (invalidBusinessName || !form.isValid) {
      setNextButton(true);
    } else {
      setNextButton(!(form.isValid && !invalidBusinessName));
    }
  }, [invalidBusinessName, form.isValid]);
  useEffect(() => {
    setNextButton(true);
  }, []);

  const onPlaceChange = ({ address }) => {
    form.setValues(
      {
        ...address,
      },
      true,
    );

    // form.setFieldValue('name', '');
    form.setFieldValue('email', '');
    // form.setFieldValue('phoneNumber', '');
    form.setTouched({}, true);
    form.setTouched({ name: false });
    setInvalidZip(false);

    // Mark fields as touched
    // trigger();
  };
  const onZipBlur = (e) => {
    const value = e.target.value;
    form.handleChange(e);
    if (value.trim() !== '') {
      installationService
        .zipCodeValidation(form.values.country, value)
        .then(({ data }) => {
          if (data.status === 'OK') {
            setInvalidZip(false);
          } else {
            setInvalidZip(true);
          }
        });
    } else {
      setInvalidZip(false);
    }
  };

  const isInputValid = () => {
    const regex = /^[a-zA-Z\s]*$/;
    return regex.test(form.values.city);
  };
  const isInputAddress1 = () => {
    const regex = /^(?=.*[a-zA-Z])[ ,-:#'.0-9a-zA-Z]*$/;
    return regex.test(form.values.city);
  };
  const isInputAddress2 = () => {
    const regex = /^(?=.*[a-zA-Z])[ ,-:#'.0-9a-zA-Z]*$/;
    return regex.test(form.values.city);
  };
  const onCountryChange = (e) => {
    form.setFieldValue('state', '');
    form.setFieldValue('country', e.target.value);
    setCountryValue(e.target.value);
  };
  const isLoading = status === 'loading';
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);
  const [dropdownClicked, setDropdownClicked] = useState(false);
  const handleState = (e) => {
    form.setFieldValue('state', e.target.value);
    // alert('hello');
    // form.handleChange(e);
  };
  const [stateValueCheck, setStateValueCheck] = useState(false);
  const handleStateClick = (event) => {
    // Set the temporary value to what is currently selected
    // setTempValue(event.target.value);
    if (countryValue !== '') {
      handleState();
      setStateValueCheck(false);
      setDropdownClicked(true);
    } else {
      form.setFieldValue('state', '');
      setStateValueCheck(true);
      setDropdownClicked(false);
      // alert('emptyCountry');
    }
  };
  const handleClose = () => {
    setStateValueCheck(false);
  };
  const handleBlur = (e) => {
    const value = e.target.value;
    form.handleChange(e);
    localStorage.setItem('businessValue', value);
    if (value.trim() !== '') {
      installationService.getBusinessname(userId, value).then(({ data }) => {
        if (data.response) {
          setInvalidBusinessName(true);
        } else {
          setInvalidBusinessName(false);
        }
      });
    } else {
      setInvalidBusinessName(false);
    }
  };
  const handleCountryClick = () => {};
  return (
    <div className={classes.NewLocaitonCont}>
      <div className={classes.NewLocatonForm}>
        <form onSubmit={form.handleSubmit}>
          <AutoComplete onPlaceChange={onPlaceChange} />
          <div className={classes.NewLocationFormCont}>
            <div className={classes.feildrow} ref={formRef}>
              {/* <MappedInput
                fields={f['rowOne']}
                form={form}
                onChange={handleBlur}
              /> */}
              {f['rowOne'][0]?.name && (
                <InputField
                  {...{
                    ...f['rowOne'][0],
                    key: `${f['rowOne'].name}`,
                    onChange: handleBlur,
                    value: form.values[f['rowOne'][0].name],
                    // value: businessNameValue,
                    errors: form.errors[f['rowOne'][0].name],
                    name: f['rowOne'][0].name,
                    // name: businessNameValue,
                    touched: form.touched[f['rowOne'][0].name],
                  }}
                  className={
                    invalidBusinessName &&
                    form.values[f['rowOne'][0].name] !== '' &&
                    classes.fieldRow
                  }
                />
              )}
              {invalidBusinessName && form.values.name !== '' && (
                <p className={classes.businessNameError}>
                  Business Name is Already Exist.
                </p>
              )}
            </div>
            <div className={classes.feildrow}>
              <div>
                <InputController
                  el={f['rowTwo'][0]}
                  form={form}
                  className={
                    form.errors?.address1 &&
                    !isInputAddress1() &&
                    form.values[f['rowTwo'][0].name] !== '' &&
                    classes.fieldRow
                  }
                />
                {/* {form.errors?.address1 &&
                  form.values[f['rowTwo'][0].name] !== '' &&
                  !isInputAddress1() &&
                  form.touched.city === undefined && (
                    <p
                      style={{
                        textAlign: 'left',
                        color: '#c51e1e',
                        marginTop: '-19px',
                      }}
                    >
                      please Enter a Valid Address1
                    </p>
                  )} */}
              </div>
              <div>
                <InputController
                  el={f['rowTwo'][1]}
                  form={form}
                  className={
                    form.errors?.address2 &&
                    !isInputAddress2() &&
                    form.values[f['rowFour'][0].name] !== '' &&
                    classes.fieldRow
                  }
                />
                {form.errors?.address2 &&
                  form.values[f['rowTwo'][1].name] !== '' &&
                  !isInputAddress2() &&
                  form.touched.city === undefined && (
                    <p
                      style={{
                        textAlign: 'left',
                        color: '#c51e1e',
                        marginTop: '-19px',
                      }}
                    >
                      please Enter a Valid Address2
                    </p>
                  )}
              </div>

              {/* <MappedInput fields={f['rowTwo']} form={form} /> */}
            </div>
            <div className={classes.feildrow}>
              {f['rowThree'][0]?.name && (
                <DropdownField
                  {...{
                    ...f['rowThree'][0],
                    key: `${f['rowThree'][0].name}`,
                    onChange: onCountryChange,
                    onClick: handleCountryClick,
                    value: form.values[f['rowThree'][0].name],
                    errors: form.errors[f['rowThree'][0].name],
                    name: f['rowThree'][1].name,
                    touched: form.touched[f['rowThree'][0].name],
                  }}
                  className={
                    invalidZip &&
                    form.values[f['rowThree'][0].name] !== '' &&
                    classes.fieldRow
                  }
                />
              )}
              {f['rowThree'][1]?.name && (
                <DropdownField
                  {...{
                    ...f['rowThree'][1],
                    key: `${f['rowThree'][1].name}`,
                    onClick: handleStateClick,
                    onChange: handleState,
                    value: form.values[f['rowThree'][1].name],
                    errors: form.errors[f['rowThree'][1].name],
                    name: f['rowThree'][1].name,
                    touched: form.touched[f['rowThree'][1].name],
                  }}
                  className={
                    invalidZip &&
                    form.values[f['rowThree'][1].name] !== '' &&
                    classes.fieldRow
                  }
                />
              )}
              {/* <InputController
                el={f['rowThree'][1]}
                onBlur={handleState}
                form={form}
              /> */}
            </div>
            <div>
              <div className={classes.feildrow}>
                <div>
                  <InputController
                    el={f['rowFour'][0]}
                    form={form}
                    className={
                      form.errors?.city &&
                      !isInputValid() &&
                      form.values[f['rowFour'][0].name] !== '' &&
                      classes.fieldRow
                    }
                  />
                  {form.errors?.city &&
                    form.values[f['rowFour'][0].name] !== '' &&
                    !isInputValid() &&
                    form.touched.city === undefined && (
                      <p
                        style={{
                          textAlign: 'left',
                          color: '#c51e1e',
                          marginTop: '-15px',
                        }}
                      >
                        Special Characters are not allowed!!
                      </p>
                    )}
                </div>
                {f['rowFour'][1]?.name && (
                  <InputField
                    {...{
                      ...f['rowFour'][1],
                      key: `${f['rowFour'][1].name}`,
                      onChange: onZipBlur,
                      value: form.values[f['rowFour'][1].name],
                      errors: form.errors[f['rowFour'][1].name],
                      name: f['rowFour'][1].name,
                      touched: form.touched[f['rowFour'][1].name],
                    }}
                    className={
                      invalidZip &&
                      form.values[f['rowFour'][1].name] !== '' &&
                      classes.fieldRow
                    }
                  />
                )}
              </div>
              {invalidZip && form.values[f['rowFour'][1].name] !== '' && (
                <p
                  style={{
                    textAlign: 'right',
                    color: '#c51e1e',
                    marginTop: '-15px',
                  }}
                >
                  Invalid Zip Code
                </p>
              )}
            </div>
            <div className={classes.feildrow}>
              <MappedInput fields={f['rowFive']} form={form} />
            </div>
          </div>
          <Dialog
            open={stateValueCheck}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <p>Please select a country before choosing a state.</p>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </form>
      </div>
    </div>
  );
}
export default NewLocation;
