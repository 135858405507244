import React, { useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import { instalationQuery } from '../Installation.query';
import { installationStore } from '@Store/installationStore';
import { StyledRadioControl } from '@Components/Radio';
import classes from './InstallerTypes.module.scss';
import { shallow } from 'zustand/shallow';
import { getUserSFDetails } from 'utils/getUserAccount';
import { dashboardQuery } from './../../dahboard/dashboard.query';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { Button } from '@Components/Button';

const RenderInstaller = ({ data }) => {
  const userData = getUserSFDetails();

  if (
    userData.installerZipcode ||
    userData.userRole === 'Distributor' ||
    userData.userRole === 'Installer'
  ) {
    const result = data.filter((ele) => ele.installerType !== 'Self Install');

    return result.map((ele) => (
      <StyledRadioControl key={ele.id} value={ele.id} label={ele.description} />
    ));
  } else {
    return data.map((ele) => (
      <StyledRadioControl key={ele.id} value={ele.id} label={ele.description} />
    ));
  }
};
const RenderInstallerMemo = React.memo(RenderInstaller);

const InstallerTypes = () => {
  const { data } = instalationQuery.useInstallerTypes();
  const selectedEquipmentId = installationStore(
    (state) => state.selectedEquipmentId,
    shallow,
  );

  const { id } = useParams();
  const email = getUserSFDetails().email;
  // const { data: serialNumberInfo } = dashboardQuery.useProduct(email, id, true);
  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');
  const { data: serialNumberInfo } = dashboardQuery.useMultiProducts(
    email,
    ids,
    true,
  );

  const { setequipment, setEquipmentTypes } = installationStore();

  const userData = getUserSFDetails();

  useEffect(() => {
    if (data) {
      setEquipmentTypes(data);

      if (
        (userData.installerZipcode && !selectedEquipmentId) ||
        userData.userRole === 'Distributor' ||
        userData.userRole === 'Installer'
      ) {
        handleChange({ target: { value: selectedEquipmentId || 2 } });
      } else if (!selectedEquipmentId) {
        handleChange({ target: { value: data[0]?.id || 1 } });
      }
    }
  }, [data]);

  if (!data) return null;
  const handleChange = ({ target }) => {
    setequipment(target.value);
    if (window.gtag) {
      window.gtag('event', ' InstalltionType_button_click', {
        event_category: target.value,
        event_label: target.value,
        value: target.value,
      });
      console.warn('gtag event InstalltionType_button_click clicked');
    } else {
      console.warn('gtag is not defined yet');
    }
  };

  return (
    <div className={classes.instalMiddleLeftCont}>
      <FormControl>
        <h2>How was the equipment installed?</h2>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          name="radio-buttons-group"
          onChange={handleChange}
          value={selectedEquipmentId}
        >
          <RenderInstallerMemo data={data} />
          <div>
            {' '}
            <Modal
              open={
                (serialNumberInfo?.statusId === 1 &&
                  serialNumberInfo?.statusMessage ===
                    'Product Already Registered!!') ||
                serialNumberInfo === null
              }
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box className={classes.ModalStyle}>
                {serialNumberInfo === null ? (
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className={classes.modalTitle}
                  >
                    Equipment not found
                  </Typography>
                ) : (
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    className={classes.modalTitle}
                  >
                    {serialNumberInfo?.statusMessage}
                  </Typography>
                )}
                {serialNumberInfo === null ? (
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                    className={classes.paraGraph}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: '600' }}>
                        Equipment not found
                      </span>
                      , please click on back button to Register New Product
                    </div>
                  </Typography>
                ) : (
                  <Typography
                    id="modal-modal-description"
                    sx={{ mt: 2 }}
                    className={classes.paraGraph}
                  >
                    <div style={{ textAlign: 'center' }}>
                      <span style={{ fontWeight: '600' }}>
                        Product Already Registered
                      </span>
                      , please click on back button to Register New Product
                    </div>
                  </Typography>
                )}
                <div className={classes.verifyButton}>
                  <Link to="/Dashboard">
                    <Button variant="contained" color="primary">
                      Back
                    </Button>
                  </Link>
                </div>
              </Box>
            </Modal>
          </div>
        </RadioGroup>
      </FormControl>
    </div>
  );
};

export default React.memo(InstallerTypes);
