import React, { useEffect, useState, useRef, useCallback } from 'react';
import useClickHandlers from 'features/Installation/hooks/useClickHandlers';
import classes from '../InstallationDetails.module.scss';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { installationStore } from '@Store/installationStore';
import { toast } from 'react-toastify';
import { getUserSFDetails } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';
import { dashboardQuery } from '../../../dahboard/dashboard.query';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import captureImg from '../../../../assets/capture_vsg_ID.svg';
import uploada from '../../../../assets/download_vsg_ID.svg';
import uploadDisabled from '../../../../assets/dashboardICons/upload_disabled_grayBG.svg';
import CameraCaptureDisabled from '../../../../assets/dashboardICons/CameraCapture_disabled_grayBG.svg';
import switchCamera from '../../../../assets/switchCamera.svg';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Webcam from 'react-webcam';
import { Button } from '@Components/Button';
const ACService = () => {
  const {
    installationDateValue,
    setStepPrev,
    setWheelServiceDetails,
    setNextButton,
    wheelServiceDetails,
    setInstallationDateDetails,
    installationDetails,
    setInstallationDetails,
    setInstallationDetailsData,
  } = installationStore();
  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');

  const email = getUserSFDetails().email;
  const { data, isLoading } = dashboardQuery.useMultiProducts(email, ids, true);
  const webcamRef = useRef(null); // create a webcam reference
  const popup = useRef();
  const [imgSrc, setImgSrc] = useState(); // initialize it
  //alert for redirecting
  useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };
    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);
  const { setSpinner, isActive } = spinnerStore();
  const [equipData, setEquipData] = useState(data);
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
    if (data) {
      setEquipData(data);
    }
  }, [data]);
  // State to track the number of employees selected, default is 1
  const [numEmployees, setNumEmployees] = useState(1);

  // State to track the names of the employees
  const [employees, setEmployees] = useState([{ firstName: '', lastName: '' }]);
  const [imageDataAC, setImageDataAC] = useState();
  const [imageName, setImageName] = useState();
  // Load data from Storage when the component mounts
  useEffect(() => {
    if (wheelServiceDetails.length >= 1) {
      const storedData = JSON.parse(wheelServiceDetails);
      console.warn(storedData);
      if (storedData) {
        setEmployees(
          storedData?.employees || [{ firstName: '', lastName: '' }],
        );
        setNumEmployees(storedData?.numEmployees || 1);
        setImageDataAC(storedData?.image || '');
        setImageName(storedData?.image?.name || '');
      }
    }
  }, []);
  console.warn(imageName, 'imageName');

  const formatDate = (date) => date.toISOString().split('T')[0];
  const currentDate = dayjs();
  const isEmpty = (installationDateValue) =>
    JSON.stringify(installationDateValue) === '{}';
  const [value, setValue] = useState(
    !isEmpty(installationDateValue)
      ? dayjs(installationDateValue).toISOString()
      : dayjs(new Date()).toISOString(),
  );
  const getStartMonthsAgo = () => {
    // Create a new Date object for the current date
    const now = new Date();
    // Calculate the date six months ago
    const MonthsAgo = new Date(now.setMonth(now.getMonth() - 2));
    return formatDate(MonthsAgo);
  };
  //WheelService Upload image Logic starts from here
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const [timestamp, setTimestamp] = useState(Date.now());

  useEffect(() => {
    // Update timestamp every second
    const interval = setInterval(() => {
      setTimestamp(Date.now());
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);
  const [file, setFile] = useState();
  const [uploadedImg, setUploadedImg] = useState();
  const [uploadedPhoto, setUploadedPhoto] = useState();
  const handleFileChange = async (e) => {
    if (e.target.files.length === 0) {
      // No file selected, ignore\

      return;
    } else {
      setFile(e.target.files[0]);
      const selectedImagedetails = e.target.files[0];
      if (!selectedImagedetails.type.startsWith('image/')) {
        toast.error('Please select an image file!', {
          position: toast.POSITION.TOP_CENTER,
        });
        //  setFileSize(null);
        return;
      }
      // Validate the file size (5 MB = 5 * 1024 * 1024 bytes)
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (selectedImagedetails.size > maxSizeInBytes) {
        toast.error(
          'The selected warranty image exceeds the 5 MB size limit. Please choose a smaller file and try again.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
        //  setFileSize(null);
        return;
      }
      setUploadedImg(URL.createObjectURL(e.target.files[0]));
      const uploadImgURL = await convertBase64(selectedImagedetails);
      const imageSplit = uploadImgURL.split(',');
      const namesplit = selectedImagedetails.name.split('.');
      let fileName = equipData[0].equipmentNumber + '_' + timestamp;
      namesplit.forEach((item, index) => {
        if (index > 0) {
          fileName = fileName + '.' + item;
        }
      });
      const imageDetails = {
        name: fileName,
        size: selectedImagedetails.size,
        type: selectedImagedetails.type,
        uri: imageSplit[1],
        imageType: 2,
      };
      setImageDataAC(imageDetails);
      setImageName(imageDetails?.name);
    }
  };

  // Update the number of employees and generate a new array for inputs
  const handleNumEmployeesChange = (event) => {
    const count = parseInt(event.target.value, 10);
    setNumEmployees(count);

    // Generate an array of empty employee objects based on the selected count
    const newEmployees = Array.from({ length: count }, (_, index) => ({
      firstName: employees[index]?.firstName || '',
      lastName: employees[index]?.lastName || '',
    }));

    setEmployees(newEmployees);
  };

  // Handle changes in individual input fields
  const handleInputChange = (index, field, value) => {
    const updatedEmployees = [...employees];
    // Validate alphanumeric characters
    if (/^[a-zA-Z0-9]*$/.test(value) || value === '') {
      updatedEmployees[index][field] = value;
      setEmployees(updatedEmployees);
      // Clear error when the user starts typing
      if (value && fieldErrors[`${field}-${index}`]) {
        setFieldErrors((prevErrors) => {
          const newErrors = { ...prevErrors };
          delete newErrors[`${field}-${index}`]; // Remove error when field has value
          return newErrors;
        });
      }
    } else {
      // Optionally, set error if input is invalid
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [`${field}-${index}`]: 'Only alphanumeric characters are allowed.',
      }));
    }
  };
  const [fieldErrors, setFieldErrors] = useState({}); // To store error messages on focus
  const handleFocusIn = (index, field) => {
    const fieldValue = employees[index][field];
    if (!fieldValue) {
      setFieldErrors((prevErrors) => ({
        ...prevErrors,
        [`${field}-${index}`]: `${field} is required.`, // Show error if field is empty
      }));
    }
  };
  // Check if all required fields are filled
  const isFormValid = employees
    .slice(0, numEmployees)
    .every(
      (employee) =>
        employee.firstName.trim() !== '' && employee.lastName.trim() !== '',
    );

  // useEffect(() => {
  //   console.warn(isFormValid, 'isemployeesFormValid');
  //   setNextButton(!isFormValid);
  // }, [employees, numEmployees, isFormValid]);

  // Prepare data for submission
  const employeeData = {
    numEmployees: numEmployees,
    employees: employees, // List of employees (firstName, lastName)
    image: imageDataAC,
  };
  //Extended Warranty logic
  const generateExtenedWarrantyData = (initData) => {
    const updatedData = initData.map((item) => {
      if (installationDetails) {
        const existData = installationDetails.find((existingItem) => {
          return (
            existingItem.equipmentNumber.toLowerCase() ===
            item?.equipmentNumber.toLowerCase()
          );
        });
        if (existData) {
          return existData;
        }
      }
      const newData = {
        productCode: item?.productCode,
        equipmentNumber: item?.equipmentNumber,
        equipmentId: item?.equipmentId,
        hasextendedWarranty: false,
      };
      return newData;
    });
    return updatedData;
  };
  const [warrayntDATA, setWarrantyDATA] = useState(
    generateExtenedWarrantyData(data),
  );
  const singleData = [];
  const hasextendedWarranty = warrayntDATA[0].hasextendedWarranty ?? false;
  const identificationNum = warrayntDATA[0].identificationNum ?? '';
  const isEnabled = !hasextendedWarranty;
  const imageData = warrayntDATA[0].image ?? false;
  const [imgCheck, setImgCheck] = React.useState(false);
  const [uploadCheck, setUploadCheck] = useState(false);
  const [verify, setVerify] = useState(false);
  const [selectedImage, setSelectedImage] = useState();

  const [isValid, setIsValid] = useState(true); // To keep track of validity

  const [open, setOpen] = useState(true);
  const [checked, setChecked] = useState(checkingData());
  function checkingData() {
    if (installationDetails) {
      const existingDatachecking = installationDetails.filter((item) => {
        return item.image ?? false;
      });
      return existingDatachecking.length > 0;
    }
    return false;
  }
  const handleChange = (e) => {
    if (e.target.checked) {
      setChecked(true);
    } else {
      // setWarrantyDATA(generateExtenedWarrantyData(data));
      // setWarrantyDATA('');
      setInstallationDetails('');
      setInstallationDetailsData('');
      setTimeout(() => {
        setWarrantyDATA(generateExtenedWarrantyData(data));
      }, 200);
      setVerify(false);
      setChecked(false);
    }
    setOpen(e.target.checked);
  };
  const [isIdNumValid, setIsIdNumValid] = useState(false);
  // Regex pattern for validation
  const regex = /^rl[a-zA-Z0-9]{2,10}$/i;
  const handleInputChangeWarranty = (event) => {
    const value = event.target.value;
    if (value !== '') {
      // const validation = regex.test(value);
      if (regex.test(value)) {
        setIsValid(true);
        setIsIdNumValid(true);
      } else {
        setIsIdNumValid(false);
        setIsValid(false);
      }
    } else {
      setIsValid(true);
      setIsIdNumValid(false);
    }
    if (warrayntDATA[0].identificationNum || warrayntDATA[0].image) {
      const singleViewData = {
        productCode: warrayntDATA[0].productCode,
        equipmentNumber: warrayntDATA[0].equipmentNumber,
        equipmentId: warrayntDATA[0].equipmentId,
        identificationNum: event.target.value,
        image: warrayntDATA[0].image,
        hasextendedWarranty: isEnabled,
      };
      singleData.push(singleViewData);
      setWarrantyDATA(singleData);
    } else {
      const singleViewData = {
        productCode: warrayntDATA[0].productCode,
        equipmentNumber: warrayntDATA[0].equipmentNumber,
        equipmentId: warrayntDATA[0].equipmentId,
        identificationNum: event.target.value,
        image: imageData,
        hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
      };
      singleData.push(singleViewData);
      setWarrantyDATA(singleData);
    }
  };
  const [imageOpen, setImageOpen] = React.useState(false);

  const handleClickOpen = () => {
    setImageOpen(true);
  };
  const handleClose = () => {
    setImageOpen(false);
  };

  const [photoOpen, setPhotoOpen] = useState(false);
  const handleClickOpen2 = () => {
    setPhotoOpen(true);
  };
  const handleClose2 = () => {
    setPhotoOpen(false);
  };
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [openDailogue, setOpenDailogue] = useState(false);
  const handleClose1 = () => {
    setOpenDailogue(false);
  };
  const [uploadedDetails, setUploadedDetails] = useState();
  const handleupload = async (e) => {
    if (e.target.files.length === 0) {
      // No file selected, ignore\
      setUploadCheck(false);
      return;
    } else {
      const selectedImagedetails = e.target.files[0];
      if (!selectedImagedetails.type.startsWith('image/')) {
        toast.error('Please select an image file!', {
          position: toast.POSITION.TOP_CENTER,
        });
        //  setFileSize(null);
        return;
      }
      // Validate the file size (5 MB = 5 * 1024 * 1024 bytes)
      const maxSizeInBytes = 5 * 1024 * 1024; // 5 MB
      if (selectedImagedetails.size > maxSizeInBytes) {
        toast.error(
          'The selected warranty image exceeds the 5 MB size limit. Please choose a smaller file and try again.',
          {
            position: toast.POSITION.TOP_CENTER,
          },
        );
        //  setFileSize(null);
        return;
      }
      setUploadedDetails(URL.createObjectURL(e.target.files[0]));
      const uploadImgURL = await convertBase64(selectedImagedetails);
      const imageSplit = uploadImgURL.split(',');
      const namesplit = selectedImagedetails.name.split('.');
      let fileName = warrayntDATA[0].equipmentNumber + '_' + timestamp;
      namesplit.forEach((item, index) => {
        if (index > 0) {
          fileName = fileName + '.' + item;
        }
      });
      const imageDetails = {
        name: fileName,
        size: selectedImagedetails.size,
        type: selectedImagedetails.type,
        uri: imageSplit[1],
        imageType: 1,
      };
      setSelectedImage(true);
      if (warrayntDATA) {
        if (warrayntDATA[0].identificationNum) {
          const singleViewData = {
            productCode: warrayntDATA[0].productCode,
            equipmentNumber: warrayntDATA[0].equipmentNumber,
            equipmentId: warrayntDATA[0].equipmentId,
            identificationNum: warrayntDATA[0].identificationNum,
            image: imageDetails,
            hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
          };
          singleData.push(singleViewData);
          setWarrantyDATA(singleData);
        } else {
          const singleViewData = {
            productCode: warrayntDATA[0].productCode,
            equipmentNumber: warrayntDATA[0].equipmentNumber,
            equipmentId: warrayntDATA[0].equipmentId,
            identificationNum: identificationNum,
            image: imageDetails,
            hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
          };
          singleData.push(singleViewData);
          setWarrantyDATA(singleData);
        }
      }
      setUploadCheck(true);
    }
    //Google Analytics added for tracking
    if (window.gtag) {
      window.gtag('event', 'Warranty_Certificate_uploaded', {
        event_category: 'interaction',
        event_label: 'Warranty_Certificate_uploaded',
        value: 1,
      });
      console.warn('gtag event Warranty_Certificate_uploaded clicked');
    } else {
      console.warn('gtag is not defined yet');
    }
  };
  const removeSelectedImage = (e) => {
    e.target.value = '';
    const singleViewData = {
      productCode: warrayntDATA[0].productCode,
      equipmentNumber: warrayntDATA[0].equipmentNumber,
      equipmentId: warrayntDATA[0].equipmentId,
      identificationNum: warrayntDATA[0].identificationNum,
      image: false,
      hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
    };
    singleData.push(singleViewData);
    setWarrantyDATA(singleData);
    setUploadCheck(false);
    setSelectedImage(false);
    setImgSrc(null);
    setImgCheck(false);
  };
  const removeUploadedImage = (e) => {
    e.target.value = '';
    setUploadedImg('');
    setImageName('');
  };
  const removeCaptureImage = () => {
    setImgSrc(null);
    setImgCheck(false);
    setSelectedImage(false);
    // setUploadCheck(false);
  };
  const FACING_MODE_USER = 'user';
  const FACING_MODE_ENVIRONMENT = 'environment';

  const videoConstraints = {
    facingMode: FACING_MODE_USER,
  };
  const [facingMode, setFacingMode] = React.useState(FACING_MODE_USER);

  const handleClick = React.useCallback(() => {
    setFacingMode((prevState) =>
      prevState === FACING_MODE_USER
        ? FACING_MODE_ENVIRONMENT
        : FACING_MODE_USER,
    );
  }, []);
  // create a capture function
  const capture = () => {
    if (window.gtag) {
      window.gtag('event', 'Warranty_captured', {
        event_category: 'interaction',
        event_label: 'Warranty_captured',
        value: 1,
      });
      console.warn('gtag event Warranty_captured clicked');
    } else {
      console.warn('gtag is not defined yet');
    }
    setOpenDailogue(false);
    const imageSrc = webcamRef.current.getScreenshot();
    setUploadedDetails(imageSrc);
    const imageSplit = imageSrc.split(',');
    // const uploadImgURL = URL.createObjectURL(imageSrc);
    const imageDetails = {
      name: warrayntDATA[0].equipmentNumber + '_' + timestamp + '.jpeg',
      size: webcamRef.current.props.screenshotQuality,
      type: webcamRef.current.props.screenshotFormat,
      uri: imageSplit[1],
      imageType: 1,
    };
    if (warrayntDATA[0].identificationNum) {
      const singleViewData = {
        productCode: warrayntDATA[0].productCode,
        equipmentNumber: warrayntDATA[0].equipmentNumber,
        equipmentId: warrayntDATA[0].equipmentId,
        identificationNum: warrayntDATA[0].identificationNum,
        image: imageDetails,
        hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
      };
      singleData.push(singleViewData);
      setWarrantyDATA(singleData);
    } else {
      const singleViewData = {
        productCode: warrayntDATA[0].productCode,
        equipmentNumber: warrayntDATA[0].equipmentNumber,
        equipmentId: warrayntDATA[0].equipmentId,
        identificationNum: identificationNum,
        image: imageDetails,
        hasextendedWarranty: warrayntDATA[0].hasextendedWarranty,
      };
      singleData.push(singleViewData);
      setWarrantyDATA(singleData);
    }

    const captureImg = imageSrc ? true : false;
    setSelectedImage(captureImg);
    setImgSrc(captureImg);
  };
  const captureTheimage = () => {
    if (window.gtag) {
      window.gtag('event', 'Warranty_captured', {
        event_category: 'interaction',
        event_label: 'Warranty_captured',
        value: 1,
      });
      console.warn('gtag event Warranty_captured clicked');
    } else {
      console.warn('gtag is not defined yet');
    }
    setOpenDailogue(true);
    setImgCheck(true);
  };
  useEffect(() => {
    setInstallationDetails(warrayntDATA);
  }, [warrayntDATA]);
  const [btnCheck, setBtnCheck] = useState(false);
  useEffect(() => {
    if (
      ((isIdNumValid || warrayntDATA[0].identificationNum) &&
        isValid &&
        warrayntDATA[0].image) ||
      verify ||
      !checked
    ) {
      setBtnCheck(true);
    } else {
      setBtnCheck(false);
    }
  }, [warrayntDATA, verify, checked, isValid]);
  useEffect(() => {
    if (isFormValid && btnCheck) {
      setNextButton(false);
    } else {
      setNextButton(true);
    }
  }, [btnCheck, isFormValid]);
  useEffect(() => {
    setInstallationDateDetails(value);
  }, [value]);
  // Convert employeeData to JSON string
  const jsonString = JSON.stringify(employeeData);
  const handleNextClick = (e) => {
    console.warn(jsonString, 'employees');
    setWheelServiceDetails(JSON.stringify(employeeData), true);
  };

  const handlePrevClick = () => {
    setStepPrev();
    setWarrantyDATA('');
    setInstallationDetails('');
    setWheelServiceDetails('');
    setInstallationDateDetails({});
  };
  useClickHandlers(handleNextClick, handlePrevClick);

  return (
    <div className={classes.installationDetailCont}>
      <div className={classes.installationDetails}>
        <div>
          <div className={classes.dateContainer}>
            <p className={classes.installationDate}>Installation Date</p>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              className={classes.dateSelect}
            >
              <DatePicker
                sx={{
                  color: '#D2D2D2',
                  borderRadius: '2px',
                  borderWidth: '1px',
                  borderColor: '#D2D2D2',
                  border: '1px solid',
                  backgroundColor: '#fff',
                  minWidth: '280px',
                  maxWidth: '420px',
                  width: '100%',
                  // height: '38px',
                }}
                views={['year', 'month', 'day']}
                defaultValue={dayjs(value)}
                minDate={dayjs(getStartMonthsAgo())}
                maxDate={dayjs(new Date())}
                selected={value}
                onChange={(value) => setValue(value.toISOString())}
              />
            </LocalizationProvider>
          </div>
          <div className={classes.wheelService}>
            <div>
              <p>Number of Employees Trained</p>
              <select
                id="numEmployees"
                value={numEmployees}
                onChange={handleNumEmployeesChange}
                className={classes.employeesTrained}
              >
                {[1, 2, 3, 4].map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>

              {/* Dynamically render input fields based on the selected number */}
              {/* Dropdown to select number of employees */}
              <div>
                {Array.from({ length: numEmployees }, (_, index) => (
                  <div
                    key={index}
                    className="employee-input"
                    style={{ marginTop: '10px' }}
                  >
                    <label
                      htmlFor={`firstName-${index}`}
                      className={classes.employeNames}
                      style={{ marginBottom: '10px', display: 'inline-block' }}
                    >
                      Employee Name #{index + 1}:
                    </label>
                    <br />
                    <label
                      htmlFor={`firstName-${index}`}
                      className={classes.employeNames}
                    >
                      First Name <span className={classes.formLabelReq}>*</span>
                    </label>
                    <input
                      type="text"
                      className={classes.employeNameInput}
                      id={`firstName-${index}`}
                      value={employees[index]?.firstName || ''}
                      placeholder="Enter Name"
                      onChange={(e) =>
                        handleInputChange(index, 'firstName', e.target.value)
                      }
                      onBlur={() => handleFocusIn(index, 'firstName')} // Validate on blur
                      aria-required="true"
                      style={{
                        borderColor: fieldErrors[`firstName-${index}`]
                          ? '#CE1F1F'
                          : '#D2D2D2',
                      }}
                    />
                    {fieldErrors[`firstName-${index}`] && (
                      <span style={{ color: '#CE1F1F', display: 'block' }}>
                        {fieldErrors[`firstName-${index}`]}
                      </span>
                    )}
                    <label
                      htmlFor={`lastName-${index}`}
                      className={classes.employeNames}
                    >
                      Last Name <span className={classes.formLabelReq}>*</span>
                    </label>
                    <input
                      type="text"
                      id={`lastName-${index}`}
                      value={employees[index]?.lastName || ''}
                      placeholder="Enter Name"
                      onChange={(e) =>
                        handleInputChange(index, 'lastName', e.target.value)
                      }
                      onBlur={() => handleFocusIn(index, 'lastName')} // Validate on blur
                      aria-required="true"
                      style={{
                        borderColor: fieldErrors[`lastName-${index}`]
                          ? '#CE1F1F'
                          : '#D2D2D2',
                      }}
                    />
                    {fieldErrors[`lastName-${index}`] && (
                      <span style={{ color: '#CE1F1F', display: 'block' }}>
                        {fieldErrors[`lastName-${index}`]}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>

            <div className={classes.chooseFile}>
              <p>Please Upload Product Photography</p>
              <div className={classes.uploadImage}>
                <label htmlFor="fileInput">Choose Image</label>
                <input
                  type="file"
                  id="fileInput"
                  name="file"
                  onChange={handleFileChange}
                />
                <span>
                  {imageName && (
                    <div className={classes.acServiceImage}>
                      {/* <img src={imageData.uri ?? ''} alt="Thumb" />
                       */}
                      <button
                        // onClick={imageViewerClick}
                        className={classes.imageViewer}
                        variant="outlined"
                        onClick={handleClickOpen2}
                      >
                        <p className={classes.hoverText}>
                          <h2 className={classes.rotaryTitle}>{imageName}</h2>
                          {/* <span className={classes.tooltip}>{imageName}</span> */}
                        </p>
                        {/* <p>{imageData.name}</p> */}
                      </button>
                      <button
                        onClick={removeUploadedImage}
                        className={classes.removeImgs}
                      >
                        X
                      </button>
                    </div>
                  )}
                </span>
              </div>
              <Dialog
                open={photoOpen}
                onClose={handleClose2}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    <img
                      src={uploadedImg}
                      alt="Thumb"
                      className={classes.imageView}
                    />
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose2}>Close</Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
          <div className={classes.checkboxCont}>
            <input
              value="test"
              className={classes.checkboxStyles}
              type="checkbox"
              onChange={handleChange}
              checked={checked}
            />{' '}
            <p className={classes.extendWaranty}>Extended Warranty</p>
            {checked ? (
              <div>
                <>
                  <>
                    <div className={classes.identificationDetails}>
                      <p>Identification number</p>
                      <input
                        type="text"
                        onChange={handleInputChangeWarranty}
                        value={warrayntDATA[0].identificationNum}
                        style={{
                          borderColor: isValid ? '#D2D2D2' : '#CE1F1F',
                          color: isValid ? '#000' : '#CE1F1F',
                        }}
                      />{' '}
                      {!isValid && (
                        <p className={classes.invalidInput}>
                          The warranty certificate ID is invalid. It must be
                          between 4 and 12 characters long, start with "RL," and
                          contain only alphanumeric characters. Please correct
                          the ID and try again.
                        </p>
                      )}
                    </div>
                    <div className={classes.warrantyDiv}>
                      <p>Please Upload/Capture warranty certificate </p>
                      <input
                        type="file"
                        id="file"
                        accept=".jpg,.jpeg,.png,.gif" // Restrict file types
                        onChange={handleupload}
                        // capture="filesystem"
                        className={classes.fileUploadButton}
                        style={{ display: 'none' }}
                        disabled={imageData}
                      />
                      <label
                        htmlFor="file"
                        className={classes.uploadFileLabel}
                        disabled={selectedImage}
                      >
                        {!imageData && (
                          <img
                            src={uploada}
                            alt="upload Identification number"
                            className={classes.imgPointer}
                          />
                        )}
                        {imageData && (
                          <img
                            src={uploadDisabled}
                            alt="disabled upload Identification number"
                          />
                        )}
                      </label>
                      <button
                        onClick={captureTheimage}
                        className={classes.captureImage}
                        disabled={imageData}
                      >
                        {!imageData && (
                          <img
                            src={captureImg}
                            alt="capture identification number"
                          />
                        )}
                        {imageData && (
                          <img
                            src={CameraCaptureDisabled}
                            alt="camera capture disabled number"
                          />
                        )}
                      </button>
                    </div>
                    {imageData && (
                      <div
                        className={
                          imageData
                            ? classes.selectedImg
                            : classes.unselectedImg
                        }
                      >
                        {/* <img src={imageData.uri ?? ''} alt="Thumb" />
                         */}
                        <button
                          // onClick={imageViewerClick}
                          className={classes.imageViewer}
                          variant="outlined"
                          onClick={handleClickOpen}
                        >
                          <p className={classes.hoverText}>
                            <h2 className={classes.rotaryTitle}>
                              {imageData.name}
                            </h2>
                            <span className={classes.tooltip}>
                              {imageData.name}
                            </span>
                          </p>
                          {/* <p>{imageData.name}</p> */}
                        </button>
                        <button
                          onClick={removeSelectedImage}
                          className={classes.removeImg}
                        >
                          X
                        </button>
                      </div>
                    )}
                    <Dialog
                      open={imageOpen}
                      onClose={handleClose}
                      aria-labelledby="alert-dialog-title"
                      aria-describedby="alert-dialog-description"
                    >
                      <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          <img
                            src={uploadedDetails}
                            alt="Thumb"
                            className={classes.imageView}
                          />
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                      </DialogActions>
                    </Dialog>
                    <div className={classes.selectedImg}>
                      {imgCheck ? (
                        imgSrc ? (
                          <></>
                        ) : (
                          <>
                            <Dialog
                              fullScreen={fullScreen}
                              open={openDailogue}
                              onClose={handleClose1}
                              aria-labelledby="responsive-dialog-title"
                              className={classes.captureScreen}
                            >
                              <DialogTitle
                                id="responsive-dialog-title"
                                className={classes.dailogHeader}
                              >
                                {'Capture the Image'}
                              </DialogTitle>
                              <DialogContent>
                                <DialogContentText
                                  className={classes.dailogCont}
                                >
                                  <Webcam
                                    className={classes.webCam}
                                    ref={webcamRef}
                                    screenshotFormat="image/jpeg"
                                    screenshotQuality={0.8}
                                    videoConstraints={{
                                      ...videoConstraints,
                                      facingMode,
                                    }}
                                  />
                                  <button
                                    onClick={handleClick}
                                    className={classes.switchCamerabtn}
                                  >
                                    <img
                                      src={switchCamera}
                                      alt="switchCamera"
                                      className={classes.switchCamera}
                                    />
                                  </button>
                                </DialogContentText>
                              </DialogContent>
                              <DialogActions>
                                <Button
                                  onClick={handleClose1}
                                  className={classes.dailogButtonCancel}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  className={classes.dailogButton}
                                  onClick={capture}
                                >
                                  Capture
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </>
                        )
                      ) : null}
                    </div>
                  </>
                </>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ACService;
