import React, { useEffect, useState } from 'react';
import useClickHandlers from '../hooks/useClickHandlers';
import { installationStore } from '@Store/installationStore';
import { useParams } from 'react-router-dom';
import { dashboardQuery } from 'features/dahboard/dashboard.query';
import { instalationQuery } from '../Installation.query';
import classes from './RegConfirmation.module.scss';
import { getUserSFDetails } from 'utils/getUserAccount';
import { spinnerStore } from '@Store/spinnerStore';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Link } from 'react-router-dom';
import { Button } from '@Components/Button';
import { Buffer } from 'buffer';
import { toast } from 'react-toastify';
// global.Buffer = require('buffer').Buffer;
function RegConfirmation() {
  const {
    setStepNext,
    setStepPrev,
    locationDetails,
    installationType,
    selectedEquipmentId,
    equipmentTypes,
    setInstallationDetailsData,
    setNewLocation,
    installationDetails,
    installationDateValue,
    isIdNumCheck,
    wheelServiceDetails,
    isWheelService,
    isTexaSerialNum,
  } = installationStore();
  const { id } = useParams();
  const ids =
    localStorage.getItem('CheckedValues') ||
    localStorage.getItem('CheckedValue');
  const email = getUserSFDetails().email;
  // const { data } = dashboardQuery.useProduct(email, ids, true);
  const { data } = dashboardQuery.useMultiProducts(email, ids, true);
  const { setSpinner, isActive } = spinnerStore();
  // console.warn(JSON.parse(wheelServiceDetails), 'wheelServiceDetails');
  const {
    mutate: warrantyMutate,
    data: warrantyData,
    isLoading: iswarrantyloadingdata,
    status: warrantyStatus,
  } = instalationQuery.useMutationMultiUploadEq();
  if (warrantyStatus) {
  }
  const [uploadedApiData, setUploadedApiData] = useState([]);
  useEffect(() => {
    if (warrantyStatus === 'success') {
      setUploadedApiData(warrantyData?.data?.response);
      setTimeout(() => {
        registerBtnAction();
      }, 1000);
    }
  }, [warrantyStatus, warrantyData]);

  const {
    mutate,
    data: productdata,
    isLoading: isloadingdata,
    status,
    error,
  } = instalationQuery.useMutationMultiRegisterEq();
  const eq = equipmentTypes?.find(
    (ele) => String(ele.id) === String(selectedEquipmentId),
  );
  const checkingData = () => {
    if (installationDetails) {
      const existingDatachecking = installationDetails.filter((item) => {
        return item.hasextendedWarranty ?? false;
      });
      return existingDatachecking.length > 0;
    }
    return false;
  };
  const warrantyCheck = checkingData();
  useEffect(() => {
    checkingData();
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () =>
      window.removeEventListener('dom.disable_beforeunload', unloadCallback);
  }, []);
  useEffect(() => {
    if (status === 'success') {
      processRegisterResponse(productdata);
      document.querySelector('#cancelRegister').style.display = 'none';
      // setStepNext();
    }
  }, [status]);
  const serviceData =
    isWheelService || isTexaSerialNum ? JSON.parse(wheelServiceDetails) : '';
  function processRegisterResponse(response) {
    const alreadyRegistered = [];
    const failedRegistration = [];
    const unknownStatus = [];
    response?.data?.response.forEach((item) => {
      if (item.statusId === 1) {
        //Success
      } else if (item.statusId === 2) {
        //Already registered
        alreadyRegistered.push(item.equipmentNumber);
      } else if (item.statusId === 3) {
        //Failed registration
        failedRegistration.push(item.equipmentNumber);
      } else {
        //Unknown
        unknownStatus.push(item.equipmentNumber);
      }
    });
    let errorSerialNumbers = [];
    if (alreadyRegistered.length > 0) {
      errorSerialNumbers.push(...alreadyRegistered);
    }

    if (failedRegistration.length > 0) {
      errorSerialNumbers.push(...failedRegistration);
    }
    if (unknownStatus.length > 0) {
      errorSerialNumbers.push(...unknownStatus);
    }
    if (errorSerialNumbers.length > 0) {
      const errorData = [];
      const alreadyRegisteredError = {
        id: errorSerialNumbers.join(','),
      };
      errorData.push(alreadyRegisteredError || errorSerialNumbers);
      // setIsRegisterApiError({
      //   isFormRegister: true,
      //   NavigateToSuccess: errorSerialNumbers.length < response.length,
      // });

      toast.error(
        'Registration of ' +
          errorSerialNumbers +
          ' attempt failed. Please try again or contact support for assistance at technicalsupport@vsgdover.com.',
        {
          position: toast.POSITION.TOP_CENTER,
        },
      );
      if (response?.data?.response.length === errorSerialNumbers.length) {
        setSpinner(false);
        return;
      } else {
        setStepNext();
        setSpinner(false);
      }

      // showToastView(errorData);
    } else {
      setStepNext();
      setSpinner(false);

      // navigation.navigate('EquipmentRegistrationSuccess');
    }
  }
  const endUserDetails = installationStore((state) => state.endUserDetails);
  let user = getUserSFDetails();
  if (endUserDetails?.id) {
    user = endUserDetails;
  }

  // const installationDateValue = localStorage.getItem('InstalDateValue');
  const installationDate = installationDateValue.split('T')[0];
  let [payload, setPayload] = useState([]);

  const generateImagesFormData = () => {
    let imagesData = [];
    console.warn(installationDetails, 'installationDetails');

    if (installationDetails.length >= 1) {
      installationDetails.forEach((data) => {
        const dataImage = data.image;
        if (dataImage) {
          const warrantyImageDetails = {
            certificateImage: '',
            name: dataImage.name,
            type: dataImage.type ?? '',
            uri: dataImage.uri,
            imageType: dataImage.imageType,
          };
          const warrantyData = {
            email: user.email ?? '',
            equipmentId: data.equipmentId,
            equipmentNumber: data.equipmentNumber,
            identificationNumber: data.identificationNum ?? '',
            // certificateImage: dataImage.uri,
            name: dataImage.name,
            type: dataImage.type ?? '',
            size: dataImage.size ?? '',
            imageType: dataImage.imageType,
          };
          const newData = {
            warrantyDetails: warrantyData,
            imageDetails: warrantyImageDetails,
          };
          imagesData.push(newData);
        }
      });
    }
    if (wheelServiceDetails.length >= 1) {
      const detailsArray = Array.isArray(wheelServiceDetails)
        ? wheelServiceDetails
        : [wheelServiceDetails];

      detailsArray.forEach((data, index) => {
        console.warn(`Processing item ${index}:`, data);
        // Parse the JSON string into an object
        const parsedObject = JSON.parse(data);

        // Encapsulate the parsed object into an array
        const detailsArray = [parsedObject];

        console.warn(detailsArray);
        const dataImage = detailsArray[0].image;
        if (dataImage) {
          const warrantyImageDetails = {
            certificateImage: '',
            name: dataImage.name,
            type: dataImage.type ?? '',
            uri: dataImage.uri,
            imageType: dataImage.imageType ?? null,
          };
          const warrantyData = {
            email: user.email ?? '',
            equipmentId: data.equipmentId,
            equipmentNumber: data.equipmentNumber,
            identificationNumber: data.identificationNum ?? '',
            // certificateImage: dataImage.uri,
            name: dataImage.name,
            type: dataImage.type ?? '',
            size: dataImage.size ?? '',
            imageType: dataImage.imageType ?? null,
          };
          const newData = {
            warrantyDetails: warrantyData,
            imageDetails: warrantyImageDetails,
          };
          imagesData.push(newData);
        }
      });
    }
    const detailsJson = [];
    const warrantyImageData = [];
    imagesData.forEach((item) => {
      detailsJson.push(item.warrantyDetails);
      warrantyImageData.push(item.imageDetails);
    });

    const imageDataJson = JSON.stringify(detailsJson);
    const jsonBase64 = Buffer.from(imageDataJson).toString('base64');
    const base64ToBlob = (base64, mime) => {
      const byteChars = atob(base64);
      const byteNumbers = new Array(byteChars.length);
      for (let i = 0; i < byteChars.length; i++) {
        byteNumbers[i] = byteChars.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    };
    const blob = base64ToBlob(jsonBase64, 'application/json');
    const formData = new FormData();
    // formData.append('formfiles', {
    //   uri: `data:application/json;base64,${jsonBase64}`,
    //   type: 'application/json',
    //   name: 'data.json',
    // });
    formData.append('formfiles', blob, 'data.json');
    warrantyImageData.forEach((image) => {
      // const imageSplit = image.uri.split(',');
      const imageBlob = base64ToBlob(image.uri, image.type);
      formData.append('formfiles', imageBlob, image.name);
      // formData.append('formfiles', {
      //   // uri: `data:${image.type};base64,${image.certificateImage}`,
      //   uri: image.uri,
      //   type: image.type,
      //   name: image.name,
      // });
    });
    warrantyMutate(formData);
    return formData;
  };
  let warrantySerialNum = [];
  if (installationDetails.length >= 1) {
    installationDetails.forEach((data) => {
      if (data.hasextendedWarranty) {
        warrantySerialNum = {
          equipmentNumber: data.equipmentNumber,
          identificationNumber: data.identificationNum ?? '',
        };
      }
    });
    // for (let i = 0; i < data.length; i++) {
    //   const equipmentInformation = data[i];
    //   const extendedWarrentyDetails = warrantySerialNum.filter((item) => {
    //     return (
    //       item.equipmentNumber.toLowerCase() ===
    //       equipmentInformation.equipmentNumber.toLowerCase()
    //     );
    //   });
    //   console.warn(extendedWarrentyDetails, 'extendedWarrentyDetails11');
    // }
  }
  function registerBtnAction() {
    if (window.gtag) {
      window.gtag('event', 'RegistrationConfirmation_button_click', {
        event_category: 'interaction',
        event_label: 'RegistrationConfirmation_button_click',
        value: 1,
      });
      console.warn('gtag event RegistrationConfirmation_button_click');
    } else {
      console.warn('gtag is not defined yet');
    }
    // register upload api payload
    let payloadSets = [];
    let hasExtendedWarrentyBool = false;
    let identificationNumber = '';
    let extenedWarrentyImageData = '';
    let serviceImageData = '';
    let warrantyImageData = [];
    for (let i = 0; i < data.length; i++) {
      if (warrantyData?.data?.response.length > 0) {
        const equipmentInformation = data[i];
        // const extendedWarrentyDetails = warrantyData?.data?.response.filter(
        //   (item) => {
        //     return (
        //       item.equipmentNumber.toLowerCase() ===
        //       equipmentInformation.equipmentNumber.toLowerCase()
        //     );
        //   },
        // );
        const extendedWarrentyDetails = warrantyData?.data?.response.filter(
          (item) => {
            const itemNumber = item?.equipmentNumber;
            const equipmentNumber = equipmentInformation?.equipmentNumber;

            // Ensure both values are strings before calling toLowerCase
            if (itemNumber && equipmentNumber) {
              return itemNumber.toLowerCase() === equipmentNumber.toLowerCase();
            }

            // Skip filtering if either value is null or undefined
            return false;
          },
        );

        if (extendedWarrentyDetails.length > 0) {
          const details = extendedWarrentyDetails[0];
          hasExtendedWarrentyBool = details.isUploaded;
          identificationNumber = details.identificationNumber ?? '';
          extenedWarrentyImageData = details.certificateCode ?? '';
        } else {
          hasExtendedWarrentyBool = false;
          identificationNumber = '';
          serviceImageData =
            warrantyData?.data?.response[0].certificateCode ?? '';
        }
        warrantyImageData = warrantyData?.data?.response;
        const processImages = (warrantyImageData) => {
          let certificationCodes = [];
          let productImageCodes = [];

          warrantyImageData.forEach((image) => {
            if (image.imageType === 1) {
              certificationCodes.push(image.certificateCode); // Assign to certificationCodes
            } else if (image.imageType === 2) {
              productImageCodes.push(image.certificateCode); // Assign to productImageCodes
            }
          });

          return {
            certificationCodes: certificationCodes.join(', '), // Convert to string
            productImageCodes: productImageCodes.join(', '), // Convert to string
          };
        };

        const { certificationCodes, productImageCodes } =
          processImages(warrantyImageData);

        extenedWarrentyImageData = certificationCodes; // Assign certificationCodes
        serviceImageData = productImageCodes; // Assign productImageCodes
        console.warn(
          certificationCodes,
          productImageCodes,
          '----------certificationCodes, productImageCodes',
        );
      }
      if (String(selectedEquipmentId) === '1') {
        payload = {
          // ...payload,
          // InstallerId: installationType?.installerId,
          equipmentId: data[i].equipmentId,
          userEmail: user.email,
          distributorId: data[i].distributorId,
          userID: user.id,
          equipmentNumber: data[i].equipmentNumber,
          accountId: locationDetails?.accountId,
          registrationSource: 'WebApp',
          InstallerTypeID: +selectedEquipmentId,
          installerLocationId: locationDetails?.id,
          RegisteredBy: getUserSFDetails()?.id,
          RegisteredByEmail: getUserSFDetails()?.email,
          installedDate: installationDate,
          installerFirstName: installationType?.fullName,
          installerLastName: installationType?.lastName || '',
          InstallerPhone: installationType?.contactNumber,
          installerEmail: installationType?.email,
          isExtendedWarranty: hasExtendedWarrentyBool,
          identificationNumber: identificationNumber,
          certificateCode: extenedWarrentyImageData || '',
          isTexaProduct: isTexaSerialNum,
          isWheelService: isWheelService,
          mileageNumber: serviceData?.mileage || '',
          employees: serviceData?.employees || [],
          productImageCode: serviceImageData || '',
        };
      }
      if (String(selectedEquipmentId) === '2') {
        payload = {
          // ...payload,
          equipmentId: data[i].equipmentId,
          userEmail: user.email,
          distributorId: data[i].distributorId,
          userID: user.id,
          equipmentNumber: data[i].equipmentNumber,
          accountId: locationDetails?.accountId,
          registrationSource: 'WebApp',
          InstallerTypeID: +selectedEquipmentId,
          installerLocationId: locationDetails?.id,
          RegisteredBy: getUserSFDetails()?.id,
          RegisteredByEmail: getUserSFDetails()?.email,
          installedDate: installationDate,
          isExtendedWarranty: hasExtendedWarrentyBool,
          identificationNumber: identificationNumber,
          certificateCode: extenedWarrentyImageData,
          InstallerId: installationType?.installerId,
          installerNumber: installationType?.installerNumber,
          installerName: installationType?.name,
          isTexaProduct: isTexaSerialNum,
          isWheelService: isWheelService,
        };
      }
      if (String(selectedEquipmentId) === '3') {
        payload = {
          // ...payload,
          // InstallerId: installationType?.installerId,
          equipmentId: data[i].equipmentId,
          userEmail: user.email,
          distributorId: data[i].distributorId,
          userID: user.id,
          equipmentNumber: data[i].equipmentNumber,
          accountId: locationDetails?.accountId,
          registrationSource: 'WebApp',
          InstallerTypeID: +selectedEquipmentId,
          installerLocationId: locationDetails?.id,
          RegisteredBy: getUserSFDetails()?.id,
          RegisteredByEmail: getUserSFDetails()?.email,
          installedDate: installationDate,
          isExtendedWarranty: hasExtendedWarrentyBool,
          identificationNumber: identificationNumber,
          certificateCode: extenedWarrentyImageData,
          InstallerPhone: installationType?.installerNumber,
          installerEmail: installationType?.installerEmail,
          companyName: installationType?.companyName,
          isTexaProduct: isTexaSerialNum,
          isWheelService: isWheelService,
        };
      }
      // setPayload((payload) => [...payload, payload]);
      payloadSets.push(payload);
    }
    mutate(payloadSets);
  }
  const handleNextClick = () => {
    if (installationDetails) {
      generateImagesFormData();
    } else {
      registerBtnAction();
    }
    // Utility function to convert Base64 to Blob
    // if (imagesData.length < 1) {
    //   console.warn('--------- no data ----------');
    //   return null;
    // }
  };
  const handlePrevClick = () => setStepPrev();

  const genrateStringByEqId = (idx) => {
    idx = String(idx);
    let string = '';
    if (idx === '1') {
      const { fullName, lastName } = installationType;
      string = fullName;
      string += lastName ? ', ' + lastName : '';
    }
    if (idx === '2') {
      const { name } = installationType;
      string = name;
    }

    if (idx === '3') {
      const { companyName } = installationType;
      string = companyName;
    }

    return string;
  };
  const getAddress = () => {
    const { address1, address2, city, state, country, zip } = locationDetails;
    return [address1, address2, city, state, country, zip]
      .filter((ele) => !!ele)
      .join(', ');
  };
  const Installer = genrateStringByEqId(selectedEquipmentId);
  const addressStr = getAddress();
  const isLoading = status === 'loading';
  const warrantyCheckData = warrantyCheck ? true : false;
  useEffect(() => {
    if (isActive !== isLoading) {
      setSpinner(isLoading);
    }
  }, [isLoading]);
  useClickHandlers(handleNextClick, handlePrevClick);
  return (
    <div className={classes.regConfirmationPage}>
      <div className={classes.regConfirmationCont}>
        <div className={classes.equipDetails}>
          <h2>Equipment Details</h2>
          {/* <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Product Name</p>
            <p className={classes.equipSubHeadDetail}>{data?.productName}</p>
          </div>
          <div className={classes.SerailsNumber}>
            <div className={classes.equipDetailsList}>
              <p className={classes.equipSubHead}>Serial Number</p>
              <p className={classes.equipSubHeadDetail}>
                {data?.equipmentNumber}
              </p>
            </div>
            <div className={classes.equipDetailsList}>
              <p className={classes.equipSubHead}>Product Code</p>
              <p className={classes.equipSubHeadDetail}>{data?.productCode}</p>
            </div>
          </div> */}
          <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Installer Type</p>
            <p className={classes.equipSubHeadDetail}>{eq?.installerType}</p>
          </div>
          <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Installer</p>
            <p className={classes.equipSubHeadDetail}>{Installer}</p>
          </div>
          <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Extended Warranty</p>
            <p className={classes.equipSubHeadDetail}>
              {warrantyCheck ? 'Yes' : 'No'}
            </p>
          </div>
          <div className={classes.equipDetailsList}>
            <p className={classes.equipSubHead}>Installation Date</p>
            <p className={classes.equipSubHeadDetail}>{installationDate}</p>
          </div>
          {isWheelService || isTexaSerialNum ? (
            <>
              <div className={classes.equipDetailsList}>
                <p className={classes.equipSubHead}>
                  Number of Employees Trained
                </p>
                <p className={classes.equipSubHeadDetail}>
                  {serviceData?.numEmployees || 'N/A'}
                </p>
              </div>
              <div className={classes.equipDetailsList}>
                {Array.from(
                  { length: serviceData?.numEmployees },
                  (_, index) => (
                    <div key={index} style={{ marginTop: '10px' }}>
                      <p className={classes.equipSubHead}>
                        Employee #{index + 1}:
                      </p>
                      <p className={classes.equipSubHeadDetail}>
                        {serviceData?.employees[index]?.firstName || 'N/A'}
                        {''} {''}
                        {serviceData?.employees[index]?.lastName || 'N/A'}
                      </p>
                    </div>
                  ),
                )}
              </div>
            </>
          ) : null}
          {isWheelService ? (
            <div className={classes.equipDetailsList}>
              <p className={classes.equipSubHead}>Mileage Number</p>
              <p className={classes.equipSubHeadDetail}>
                {serviceData?.mileage || 'N/A'}
              </p>
            </div>
          ) : null}
        </div>
        <div className={classes.locationDetails}>
          <h2>Location & Contact Details</h2>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Business Name</p>
            <p className={classes.equipSubHeadDetail}>
              {locationDetails?.name}
            </p>
          </div>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Address of the Equipment</p>
            <p className={classes.equipSubHeadDetail}>{addressStr}</p>
          </div>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Email</p>
            <p className={classes.equipSubHeadDetail}>{user.email || 'N/A'}</p>
          </div>
          <div className={classes.locationDetailList}>
            <p className={classes.equipSubHead}>Phone</p>
            <p className={classes.equipSubHeadDetail}>
              {locationDetails?.phoneNumber || 'N/A'}
            </p>
          </div>
        </div>
      </div>
      {error?.response?.data?.message === 'Product Already Registered!!' && (
        <div>
          <Modal
            open={
              error?.response?.data?.message ===
                'Product Already Registered!!' &&
              error?.response?.data?.statusCode === 400
            }
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box className={classes.ModalStyle}>
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                className={classes.modalTitle}
              >
                {error?.response?.data?.message}
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ mt: 2 }}
                className={classes.paraGraph}
              >
                <div style={{ textAlign: 'center' }}>
                  <span style={{ fontWeight: '600' }}>
                    {error?.response?.data?.message}
                  </span>
                  , please click on back button to Register New Product
                </div>
              </Typography>

              <div className={classes.verifyButton}>
                <Link to="/Dashboard">
                  <Button variant="contained" color="primary">
                    Back
                  </Button>
                </Link>
              </div>
            </Box>
          </Modal>
        </div>
      )}
    </div>
  );
}
export default RegConfirmation;
